@import 'picker';
@import 'mixins/checkbox';

// Check Picker
// ----------------------

// Check picker name space
@ckpns: ~'@{ns}@{check-picker-prefix}';

// Menu Items
.@{ckpns}-menu-items {
  .picker-menu-items-reset;
  .picker-menu-group-common(@check-picker-prefix);
  .picker-menu-group-title(@check-picker-prefix);
  .picker-menu-group-closed(@check-picker-prefix);
}

// Menu item
.@{ckpns}-menu-item {
  position: relative;
  display: block;
  padding: @picker-item-content-padding-vertical @picker-item-content-padding-horizontal @picker-item-content-padding-vertical @picker-check-item-content-padding-left;
  transition: @picker-item-transition;
  cursor: pointer;
  // Reset label styles
  margin-bottom: 0;

  // Forcing a newline
  .break-word;

  &:not(&-disabled):hover,
  &&-focus {
    .picker-item-hover;
  }

  &&-disabled {
    color: @picker-menu-item-disabled-color;
    cursor: @cursor-disabled;
  }

  .@{ckpns}-menu-group-children & {
    padding-left: @picker-check-item-content-padding-left + @picker-children-check-item-padding-left;
  }
}

// Checkbox
.@{ckpns}-menu-item-wrapper {
  position: absolute;
  width: @checkbox-width-height;
  height: @checkbox-width-height;
  display: inline-block;
  // Checkbox align with searchInput.
  left: @picker-menu-padding;
  top: @checkbox-sense-width;

  .@{ckpns}-menu-group-children & {
    left: @picker-menu-padding + @picker-children-check-item-padding-left;
  }

  [type='checkbox'] {
    display: none;
  }

  &::before,
  &::after,
  .@{ckpns}-menu-item-inner::before,
  .@{ckpns}-menu-item-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &::before,
  .@{ckpns}-menu-item-inner::before,
  .@{ckpns}-menu-item-inner::after {
    width: @checkbox-width-height;
    height: @checkbox-width-height;
  }

  // Ripple effect
  &::before {
    .checkbox-inner-wrap(@checkbox-checked-border-color);

    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    transition: transform 0.2s linear, opacity 0.2s linear;

    .@{ckpns}-menu-item-active & {
      transform: scale(1.5);
      opacity: 0;
      visibility: visible;
    }
  }

  // Sensing field
  &::after {
    top: -@checkbox-sense-width;
    right: -@checkbox-sense-width;
    bottom: -@checkbox-sense-width;
    left: -@checkbox-sense-width;
  }

  // Frame
  .@{ckpns}-menu-item-inner::before {
    .checkbox-inner-wrap(@checkbox-default-border-color);

    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;

    .@{ckpns}-menu-item-active & {
      border-color: @checkbox-checked-border-color;
      background-color: @checkbox-checked-border-color;
    }

    .@{ckpns}-menu-item-disabled:not(.@{ckpns}-menu-item-active) & {
      border-color: @checkbox-disabled-color;
      background-color: @checkbox-disabled-color;
    }

    .@{ckpns}-menu-item-disabled.@{ckpns}-menu-item-active & {
      opacity: 0.3;
    }
  }

  // check mark
  .@{ckpns}-menu-item-inner::after {
    .opacity(0);

    transform: rotate(45deg) scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);

    .@{ckpns}-menu-item-active & {
      border: solid @B000;
      width: 6px;
      height: 9px;
      margin-top: 2px;
      margin-left: 5px;
      opacity: 1;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(1);
    }
  }

  // :Focus
  &:focus {
    outline: none;

    .@{ckpns}-menu-item-inner::before {
      .checkbox-inner-active-shadow(@checkbox-default-border-color);

      @hover: ~':hover';

      @{hover}& {
        .checkbox-inner-active-shadow(@checkbox-checked-border-color);
      }

      .@{ns}checkbox-checked & {
        .checkbox-inner-active-shadow(@checkbox-checked-border-color);
      }
    }
  }
}
