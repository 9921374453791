/* stylelint-disable */
@font-face {
  font-family: @font-family-icon;
  src: url('@{icon-font-path}/rsuite-icon-font.eot');
  src: url('@{icon-font-path}/rsuite-icon-font.eot?#iefix') format('eot'),
    url('@{icon-font-path}/rsuite-icon-font.ttf') format('truetype'),
    url('@{icon-font-path}/rsuite-icon-font.woff') format('woff'),
    url('@{icon-font-path}/rsuite-icon-font.svg#rsuite-icon-font') format('svg');
  font-weight: normal;
  font-style: normal;
}

.rs-icon {
  //* use !important to prevent issues with browser extensions that change fonts */
  font-family: @font-family-icon !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  display: inline-block;
  text-transform: none;
  font-size: 14px;
  line-height: 1;
  //* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rs-icon-500px {
  &::before {
    content: @icon-500px;
  }
}

.rs-icon-address-book {
  &::before {
    content: @icon-address-book;
  }
}

.rs-icon-address-book-o {
  &::before {
    content: @icon-address-book-o;
  }
}

.rs-icon-adjust {
  &::before {
    content: @icon-adjust;
  }
}

.rs-icon-adn {
  &::before {
    content: @icon-adn;
  }
}

.rs-icon-align-center {
  &::before {
    content: @icon-align-center;
  }
}

.rs-icon-align-justify {
  &::before {
    content: @icon-align-justify;
  }
}

.rs-icon-align-left {
  &::before {
    content: @icon-align-left;
  }
}

.rs-icon-align-right {
  &::before {
    content: @icon-align-right;
  }
}

.rs-icon-alipay {
  &::before {
    content: @icon-alipay;
  }
}

.rs-icon-amazon {
  &::before {
    content: @icon-amazon;
  }
}

.rs-icon-ambulance {
  &::before {
    content: @icon-ambulance;
  }
}

.rs-icon-anchor {
  &::before {
    content: @icon-anchor;
  }
}

.rs-icon-android {
  &::before {
    content: @icon-android;
  }
}

.rs-icon-android2 {
  &::before {
    content: @icon-android2;
  }
}

.rs-icon-angellist {
  &::before {
    content: @icon-angellist;
  }
}

.rs-icon-angle-double-down {
  &::before {
    content: @icon-angle-double-down;
  }
}

.rs-icon-angle-double-left {
  &::before {
    content: @icon-angle-double-left;
  }
}

.rs-icon-angle-double-right {
  &::before {
    content: @icon-angle-double-right;
  }
}

.rs-icon-angle-double-up {
  &::before {
    content: @icon-angle-double-up;
  }
}

.rs-icon-angle-down {
  &::before {
    content: @icon-angle-down;
  }
}

.rs-icon-angle-left {
  &::before {
    content: @icon-angle-left;
  }
}

.rs-icon-angle-right {
  &::before {
    content: @icon-angle-right;
  }
}

.rs-icon-angle-up {
  &::before {
    content: @icon-angle-up;
  }
}

.rs-icon-apple {
  &::before {
    content: @icon-apple;
  }
}

.rs-icon-archive {
  &::before {
    content: @icon-archive;
  }
}

.rs-icon-area-chart {
  &::before {
    content: @icon-area-chart;
  }
}

.rs-icon-arrow-circle-down {
  &::before {
    content: @icon-arrow-circle-down;
  }
}

.rs-icon-arrow-circle-left {
  &::before {
    content: @icon-arrow-circle-left;
  }
}

.rs-icon-arrow-circle-o-down {
  &::before {
    content: @icon-arrow-circle-o-down;
  }
}

.rs-icon-arrow-circle-o-left {
  &::before {
    content: @icon-arrow-circle-o-left;
  }
}

.rs-icon-arrow-circle-o-right {
  &::before {
    content: @icon-arrow-circle-o-right;
  }
}

.rs-icon-arrow-circle-o-up {
  &::before {
    content: @icon-arrow-circle-o-up;
  }
}

.rs-icon-arrow-circle-right {
  &::before {
    content: @icon-arrow-circle-right;
  }
}

.rs-icon-arrow-circle-up {
  &::before {
    content: @icon-arrow-circle-up;
  }
}

.rs-icon-arrow-down {
  &::before {
    content: @icon-arrow-down;
  }
}

.rs-icon-arrow-down-line {
  &::before {
    content: @icon-arrow-down-line;
  }
}

.rs-icon-arrow-down2 {
  &::before {
    content: @icon-arrow-down2;
  }
}

.rs-icon-arrow-left {
  &::before {
    content: @icon-arrow-left;
  }
}

.rs-icon-arrow-left-line {
  &::before {
    content: @icon-arrow-left-line;
  }
}

.rs-icon-arrow-right {
  &::before {
    content: @icon-arrow-right;
  }
}

.rs-icon-arrow-right-line {
  &::before {
    content: @icon-arrow-right-line;
  }
}

.rs-icon-arrow-up {
  &::before {
    content: @icon-arrow-up;
  }
}

.rs-icon-arrow-up-line {
  &::before {
    content: @icon-arrow-up-line;
  }
}

.rs-icon-arrow-up2 {
  &::before {
    content: @icon-arrow-up2;
  }
}

.rs-icon-arrows {
  &::before {
    content: @icon-arrows;
  }
}

.rs-icon-arrows-alt {
  &::before {
    content: @icon-arrows-alt;
  }
}

.rs-icon-arrows-h {
  &::before {
    content: @icon-arrows-h;
  }
}

.rs-icon-arrows-v {
  &::before {
    content: @icon-arrows-v;
  }
}

.rs-icon-asl-interpreting {
  &::before {
    content: @icon-asl-interpreting;
  }
}

.rs-icon-assistive-listening-systems {
  &::before {
    content: @icon-assistive-listening-systems;
  }
}

.rs-icon-asterisk {
  &::before {
    content: @icon-asterisk;
  }
}

.rs-icon-at {
  &::before {
    content: @icon-at;
  }
}

.rs-icon-attachment {
  &::before {
    content: @icon-attachment;
  }
}

.rs-icon-attribution {
  &::before {
    content: @icon-attribution;
  }
}

.rs-icon-audio-description {
  &::before {
    content: @icon-audio-description;
  }
}

.rs-icon-avatar {
  &::before {
    content: @icon-avatar;
  }
}

.rs-icon-back-arrow {
  &::before {
    content: @icon-back-arrow;
  }
}

.rs-icon-backward {
  &::before {
    content: @icon-backward;
  }
}

.rs-icon-balance-scale {
  &::before {
    content: @icon-balance-scale;
  }
}

.rs-icon-ban {
  &::before {
    content: @icon-ban;
  }
}

.rs-icon-bandcamp {
  &::before {
    content: @icon-bandcamp;
  }
}

.rs-icon-bank {
  &::before {
    content: @icon-bank;
  }
}

.rs-icon-bar-chart {
  &::before {
    content: @icon-bar-chart;
  }
}

.rs-icon-bar-chart-ranking {
  &::before {
    content: @icon-bar-chart-ranking;
  }
}

.rs-icon-barcode {
  &::before {
    content: @icon-barcode;
  }
}

.rs-icon-bars {
  &::before {
    content: @icon-bars;
  }
}

.rs-icon-battery {
  &::before {
    content: @icon-battery;
  }
}

.rs-icon-battery-0 {
  &::before {
    content: @icon-battery-0;
  }
}

.rs-icon-battery-1 {
  &::before {
    content: @icon-battery-1;
  }
}

.rs-icon-battery-2 {
  &::before {
    content: @icon-battery-2;
  }
}

.rs-icon-battery-3 {
  &::before {
    content: @icon-battery-3;
  }
}

.rs-icon-bed {
  &::before {
    content: @icon-bed;
  }
}

.rs-icon-beer {
  &::before {
    content: @icon-beer;
  }
}

.rs-icon-behance {
  &::before {
    content: @icon-behance;
  }
}

.rs-icon-behance-square {
  &::before {
    content: @icon-behance-square;
  }
}

.rs-icon-bell {
  &::before {
    content: @icon-bell;
  }
}

.rs-icon-bell-o {
  &::before {
    content: @icon-bell-o;
  }
}

.rs-icon-bell-slash {
  &::before {
    content: @icon-bell-slash;
  }
}

.rs-icon-bell-slash-o {
  &::before {
    content: @icon-bell-slash-o;
  }
}

.rs-icon-bicycle {
  &::before {
    content: @icon-bicycle;
  }
}

.rs-icon-binoculars {
  &::before {
    content: @icon-binoculars;
  }
}

.rs-icon-birthday-cake {
  &::before {
    content: @icon-birthday-cake;
  }
}

.rs-icon-bitbucket {
  &::before {
    content: @icon-bitbucket;
  }
}

.rs-icon-bitbucket-square {
  &::before {
    content: @icon-bitbucket-square;
  }
}

.rs-icon-black-tie {
  &::before {
    content: @icon-black-tie;
  }
}

.rs-icon-blind {
  &::before {
    content: @icon-blind;
  }
}

.rs-icon-bluetooth {
  &::before {
    content: @icon-bluetooth;
  }
}

.rs-icon-bluetooth-b {
  &::before {
    content: @icon-bluetooth-b;
  }
}

.rs-icon-bold {
  &::before {
    content: @icon-bold;
  }
}

.rs-icon-bolt {
  &::before {
    content: @icon-bolt;
  }
}

.rs-icon-bomb {
  &::before {
    content: @icon-bomb;
  }
}

.rs-icon-book {
  &::before {
    content: @icon-book;
  }
}

.rs-icon-book2 {
  &::before {
    content: @icon-book2;
  }
}

.rs-icon-bookmark {
  &::before {
    content: @icon-bookmark;
  }
}

.rs-icon-bookmark-o {
  &::before {
    content: @icon-bookmark-o;
  }
}

.rs-icon-braille {
  &::before {
    content: @icon-braille;
  }
}

.rs-icon-briefcase {
  &::before {
    content: @icon-briefcase;
  }
}

.rs-icon-btc {
  &::before {
    content: @icon-btc;
  }
}

.rs-icon-btn-off {
  &::before {
    content: @icon-btn-off;
  }
}

.rs-icon-btn-on {
  &::before {
    content: @icon-btn-on;
  }
}

.rs-icon-bug {
  &::before {
    content: @icon-bug;
  }
}

.rs-icon-building {
  &::before {
    content: @icon-building;
  }
}

.rs-icon-building-o {
  &::before {
    content: @icon-building-o;
  }
}

.rs-icon-building2 {
  &::before {
    content: @icon-building2;
  }
}

.rs-icon-bullhorn {
  &::before {
    content: @icon-bullhorn;
  }
}

.rs-icon-bullseye {
  &::before {
    content: @icon-bullseye;
  }
}

.rs-icon-bus {
  &::before {
    content: @icon-bus;
  }
}

.rs-icon-buysellads {
  &::before {
    content: @icon-buysellads;
  }
}

.rs-icon-cab {
  &::before {
    content: @icon-cab;
  }
}

.rs-icon-calculator {
  &::before {
    content: @icon-calculator;
  }
}

.rs-icon-calendar {
  &::before {
    content: @icon-calendar;
  }
}

.rs-icon-calendar-check-o {
  &::before {
    content: @icon-calendar-check-o;
  }
}

.rs-icon-calendar-minus-o {
  &::before {
    content: @icon-calendar-minus-o;
  }
}

.rs-icon-calendar-o {
  &::before {
    content: @icon-calendar-o;
  }
}

.rs-icon-calendar-plus-o {
  &::before {
    content: @icon-calendar-plus-o;
  }
}

.rs-icon-calendar-times-o {
  &::before {
    content: @icon-calendar-times-o;
  }
}

.rs-icon-camera {
  &::before {
    content: @icon-camera;
  }
}

.rs-icon-camera-retro {
  &::before {
    content: @icon-camera-retro;
  }
}

.rs-icon-car {
  &::before {
    content: @icon-car;
  }
}

.rs-icon-caret-down {
  &::before {
    content: @icon-caret-down;
  }
}

.rs-icon-caret-left {
  &::before {
    content: @icon-caret-left;
  }
}

.rs-icon-caret-right {
  &::before {
    content: @icon-caret-right;
  }
}

.rs-icon-caret-up {
  &::before {
    content: @icon-caret-up;
  }
}

.rs-icon-cart-arrow-down {
  &::before {
    content: @icon-cart-arrow-down;
  }
}

.rs-icon-cart-plus {
  &::before {
    content: @icon-cart-plus;
  }
}

.rs-icon-cc {
  &::before {
    content: @icon-cc;
  }
}

.rs-icon-cc-amex {
  &::before {
    content: @icon-cc-amex;
  }
}

.rs-icon-cc-diners-club {
  &::before {
    content: @icon-cc-diners-club;
  }
}

.rs-icon-cc-discover {
  &::before {
    content: @icon-cc-discover;
  }
}

.rs-icon-cc-jcb {
  &::before {
    content: @icon-cc-jcb;
  }
}

.rs-icon-cc-mastercard {
  &::before {
    content: @icon-cc-mastercard;
  }
}

.rs-icon-cc-paypal {
  &::before {
    content: @icon-cc-paypal;
  }
}

.rs-icon-cc-stripe {
  &::before {
    content: @icon-cc-stripe;
  }
}

.rs-icon-cc-visa {
  &::before {
    content: @icon-cc-visa;
  }
}

.rs-icon-certificate {
  &::before {
    content: @icon-certificate;
  }
}

.rs-icon-character-area {
  &::before {
    content: @icon-character-area;
  }
}

.rs-icon-character-authorize {
  &::before {
    content: @icon-character-authorize;
  }
}

.rs-icon-charts {
  &::before {
    content: @icon-charts;
  }
}

.rs-icon-charts-line {
  &::before {
    content: @icon-charts-line;
  }
}

.rs-icon-check {
  &::before {
    content: @icon-check;
  }
}

.rs-icon-check-circle {
  &::before {
    content: @icon-check-circle;
  }
}

.rs-icon-check-circle-o {
  &::before {
    content: @icon-check-circle-o;
  }
}

.rs-icon-check-square {
  &::before {
    content: @icon-check-square;
  }
}

.rs-icon-check-square-o {
  &::before {
    content: @icon-check-square-o;
  }
}

.rs-icon-check2 {
  &::before {
    content: @icon-check2;
  }
}

.rs-icon-chevron-circle-down {
  &::before {
    content: @icon-chevron-circle-down;
  }
}

.rs-icon-chevron-circle-left {
  &::before {
    content: @icon-chevron-circle-left;
  }
}

.rs-icon-chevron-circle-right {
  &::before {
    content: @icon-chevron-circle-right;
  }
}

.rs-icon-chevron-circle-up {
  &::before {
    content: @icon-chevron-circle-up;
  }
}

.rs-icon-chevron-down {
  &::before {
    content: @icon-chevron-down;
  }
}

.rs-icon-chevron-left {
  &::before {
    content: @icon-chevron-left;
  }
}

.rs-icon-chevron-right {
  &::before {
    content: @icon-chevron-right;
  }
}

.rs-icon-chevron-up {
  &::before {
    content: @icon-chevron-up;
  }
}

.rs-icon-child {
  &::before {
    content: @icon-child;
  }
}

.rs-icon-chrome {
  &::before {
    content: @icon-chrome;
  }
}

.rs-icon-circle {
  &::before {
    content: @icon-circle;
  }
}

.rs-icon-circle-o {
  &::before {
    content: @icon-circle-o;
  }
}

.rs-icon-circle-o-notch {
  &::before {
    content: @icon-circle-o-notch;
  }
}

.rs-icon-circle-thin {
  &::before {
    content: @icon-circle-thin;
  }
}

.rs-icon-clock-o {
  &::before {
    content: @icon-clock-o;
  }
}

.rs-icon-clone {
  &::before {
    content: @icon-clone;
  }
}

.rs-icon-close {
  &::before {
    content: @icon-close;
  }
}

.rs-icon-close-circle {
  &::before {
    content: @icon-close-circle;
  }
}

.rs-icon-cloud {
  &::before {
    content: @icon-cloud;
  }
}

.rs-icon-cloud-download {
  &::before {
    content: @icon-cloud-download;
  }
}

.rs-icon-cloud-upload {
  &::before {
    content: @icon-cloud-upload;
  }
}

.rs-icon-cny {
  &::before {
    content: @icon-cny;
  }
}

.rs-icon-code {
  &::before {
    content: @icon-code;
  }
}

.rs-icon-code-fork {
  &::before {
    content: @icon-code-fork;
  }
}

.rs-icon-codepen {
  &::before {
    content: @icon-codepen;
  }
}

.rs-icon-codiepie {
  &::before {
    content: @icon-codiepie;
  }
}

.rs-icon-coffee {
  &::before {
    content: @icon-coffee;
  }
}

.rs-icon-cog {
  &::before {
    content: @icon-cog;
  }
}

.rs-icon-cogs {
  &::before {
    content: @icon-cogs;
  }
}

.rs-icon-coincide {
  &::before {
    content: @icon-coincide;
  }
}

.rs-icon-collasped {
  &::before {
    content: @icon-collasped;
  }
}

.rs-icon-collasped-o {
  &::before {
    content: @icon-collasped-o;
  }
}

.rs-icon-columns {
  &::before {
    content: @icon-columns;
  }
}

.rs-icon-comment {
  &::before {
    content: @icon-comment;
  }
}

.rs-icon-comment-o {
  &::before {
    content: @icon-comment-o;
  }
}

.rs-icon-commenting {
  &::before {
    content: @icon-commenting;
  }
}

.rs-icon-commenting-o {
  &::before {
    content: @icon-commenting-o;
  }
}

.rs-icon-comments {
  &::before {
    content: @icon-comments;
  }
}

.rs-icon-comments-o {
  &::before {
    content: @icon-comments-o;
  }
}

.rs-icon-compass {
  &::before {
    content: @icon-compass;
  }
}

.rs-icon-compress {
  &::before {
    content: @icon-compress;
  }
}

.rs-icon-connectdevelop {
  &::before {
    content: @icon-connectdevelop;
  }
}

.rs-icon-contao {
  &::before {
    content: @icon-contao;
  }
}

.rs-icon-copy {
  &::before {
    content: @icon-copy;
  }
}

.rs-icon-copy-o {
  &::before {
    content: @icon-copy-o;
  }
}

.rs-icon-copyright {
  &::before {
    content: @icon-copyright;
  }
}

.rs-icon-creative {
  &::before {
    content: @icon-creative;
  }
}

.rs-icon-creative-commons {
  &::before {
    content: @icon-creative-commons;
  }
}

.rs-icon-credit-card {
  &::before {
    content: @icon-credit-card;
  }
}

.rs-icon-credit-card-alt {
  &::before {
    content: @icon-credit-card-alt;
  }
}

.rs-icon-crop {
  &::before {
    content: @icon-crop;
  }
}

.rs-icon-crosshairs {
  &::before {
    content: @icon-crosshairs;
  }
}

.rs-icon-css3 {
  &::before {
    content: @icon-css3;
  }
}

.rs-icon-cube {
  &::before {
    content: @icon-cube;
  }
}

.rs-icon-cubes {
  &::before {
    content: @icon-cubes;
  }
}

.rs-icon-cut {
  &::before {
    content: @icon-cut;
  }
}

.rs-icon-cutlery {
  &::before {
    content: @icon-cutlery;
  }
}

.rs-icon-dashboard {
  &::before {
    content: @icon-dashboard;
  }
}

.rs-icon-dashcube {
  &::before {
    content: @icon-dashcube;
  }
}

.rs-icon-data-authorize {
  &::before {
    content: @icon-data-authorize;
  }
}

.rs-icon-data-decrease {
  &::before {
    content: @icon-data-decrease;
  }
}

.rs-icon-data-increase {
  &::before {
    content: @icon-data-increase;
  }
}

.rs-icon-database {
  &::before {
    content: @icon-database;
  }
}

.rs-icon-deaf {
  &::before {
    content: @icon-deaf;
  }
}

.rs-icon-dedent {
  &::before {
    content: @icon-dedent;
  }
}

.rs-icon-delicious {
  &::before {
    content: @icon-delicious;
  }
}

.rs-icon-desktop {
  &::before {
    content: @icon-desktop;
  }
}

.rs-icon-detail {
  &::before {
    content: @icon-detail;
  }
}

.rs-icon-deviantart {
  &::before {
    content: @icon-deviantart;
  }
}

.rs-icon-diamond {
  &::before {
    content: @icon-diamond;
  }
}

.rs-icon-digg {
  &::before {
    content: @icon-digg;
  }
}

.rs-icon-dot-circle-o {
  &::before {
    content: @icon-dot-circle-o;
  }
}

.rs-icon-down {
  &::before {
    content: @icon-down;
  }
}

.rs-icon-download {
  &::before {
    content: @icon-download;
  }
}

.rs-icon-download2 {
  &::before {
    content: @icon-download2;
  }
}

.rs-icon-dribbble {
  &::before {
    content: @icon-dribbble;
  }
}

.rs-icon-dropbox {
  &::before {
    content: @icon-dropbox;
  }
}

.rs-icon-drupal {
  &::before {
    content: @icon-drupal;
  }
}

.rs-icon-edge {
  &::before {
    content: @icon-edge;
  }
}

.rs-icon-edit {
  &::before {
    content: @icon-edit;
  }
}

.rs-icon-edit2 {
  &::before {
    content: @icon-edit2;
  }
}

.rs-icon-eercast {
  &::before {
    content: @icon-eercast;
  }
}

.rs-icon-eject {
  &::before {
    content: @icon-eject;
  }
}

.rs-icon-ellipsis-h {
  &::before {
    content: @icon-ellipsis-h;
  }
}

.rs-icon-ellipsis-v {
  &::before {
    content: @icon-ellipsis-v;
  }
}

.rs-icon-envelope {
  &::before {
    content: @icon-envelope;
  }
}

.rs-icon-envelope-o {
  &::before {
    content: @icon-envelope-o;
  }
}

.rs-icon-envelope-open {
  &::before {
    content: @icon-envelope-open;
  }
}

.rs-icon-envelope-open-o {
  &::before {
    content: @icon-envelope-open-o;
  }
}

.rs-icon-envelope-square {
  &::before {
    content: @icon-envelope-square;
  }
}

.rs-icon-envira {
  &::before {
    content: @icon-envira;
  }
}

.rs-icon-eraser {
  &::before {
    content: @icon-eraser;
  }
}

.rs-icon-etsy {
  &::before {
    content: @icon-etsy;
  }
}

.rs-icon-eur {
  &::before {
    content: @icon-eur;
  }
}

.rs-icon-exchange {
  &::before {
    content: @icon-exchange;
  }
}

.rs-icon-exclamation {
  &::before {
    content: @icon-exclamation;
  }
}

.rs-icon-exclamation-circle {
  &::before {
    content: @icon-exclamation-circle;
  }
}

.rs-icon-exclamation-circle2 {
  &::before {
    content: @icon-exclamation-circle2;
  }
}

.rs-icon-exclamation-triangle {
  &::before {
    content: @icon-exclamation-triangle;
  }
}

.rs-icon-exit {
  &::before {
    content: @icon-exit;
  }
}

.rs-icon-expand {
  &::before {
    content: @icon-expand;
  }
}

.rs-icon-expand-o {
  &::before {
    content: @icon-expand-o;
  }
}

.rs-icon-expeditedssl {
  &::before {
    content: @icon-expeditedssl;
  }
}

.rs-icon-explore {
  &::before {
    content: @icon-explore;
  }
}

.rs-icon-export {
  &::before {
    content: @icon-export;
  }
}

.rs-icon-external-link {
  &::before {
    content: @icon-external-link;
  }
}

.rs-icon-external-link-square {
  &::before {
    content: @icon-external-link-square;
  }
}

.rs-icon-eye {
  &::before {
    content: @icon-eye;
  }
}

.rs-icon-eye-slash {
  &::before {
    content: @icon-eye-slash;
  }
}

.rs-icon-eyedropper {
  &::before {
    content: @icon-eyedropper;
  }
}

.rs-icon-fa {
  &::before {
    content: @icon-fa;
  }
}

.rs-icon-facebook {
  &::before {
    content: @icon-facebook;
  }
}

.rs-icon-facebook-official {
  &::before {
    content: @icon-facebook-official;
  }
}

.rs-icon-facebook-square {
  &::before {
    content: @icon-facebook-square;
  }
}

.rs-icon-fast-backward {
  &::before {
    content: @icon-fast-backward;
  }
}

.rs-icon-fast-forward {
  &::before {
    content: @icon-fast-forward;
  }
}

.rs-icon-fax {
  &::before {
    content: @icon-fax;
  }
}

.rs-icon-female {
  &::before {
    content: @icon-female;
  }
}

.rs-icon-fighter-jet {
  &::before {
    content: @icon-fighter-jet;
  }
}

.rs-icon-file {
  &::before {
    content: @icon-file;
  }
}

.rs-icon-file-audio-o {
  &::before {
    content: @icon-file-audio-o;
  }
}

.rs-icon-file-code-o {
  &::before {
    content: @icon-file-code-o;
  }
}

.rs-icon-file-download {
  &::before {
    content: @icon-file-download;
  }
}

.rs-icon-file-excel-o {
  &::before {
    content: @icon-file-excel-o;
  }
}

.rs-icon-file-image-o {
  &::before {
    content: @icon-file-image-o;
  }
}

.rs-icon-file-movie-o {
  &::before {
    content: @icon-file-movie-o;
  }
}

.rs-icon-file-o {
  &::before {
    content: @icon-file-o;
  }
}

.rs-icon-file-pdf-o {
  &::before {
    content: @icon-file-pdf-o;
  }
}

.rs-icon-file-powerpoint-o {
  &::before {
    content: @icon-file-powerpoint-o;
  }
}

.rs-icon-file-text {
  &::before {
    content: @icon-file-text;
  }
}

.rs-icon-file-text-o {
  &::before {
    content: @icon-file-text-o;
  }
}

.rs-icon-file-upload {
  &::before {
    content: @icon-file-upload;
  }
}

.rs-icon-file-word-o {
  &::before {
    content: @icon-file-word-o;
  }
}

.rs-icon-file-zip-o {
  &::before {
    content: @icon-file-zip-o;
  }
}

.rs-icon-film {
  &::before {
    content: @icon-film;
  }
}

.rs-icon-filter {
  &::before {
    content: @icon-filter;
  }
}

.rs-icon-fire {
  &::before {
    content: @icon-fire;
  }
}

.rs-icon-fire-extinguisher {
  &::before {
    content: @icon-fire-extinguisher;
  }
}

.rs-icon-firefox {
  &::before {
    content: @icon-firefox;
  }
}

.rs-icon-first-order {
  &::before {
    content: @icon-first-order;
  }
}

.rs-icon-flag {
  &::before {
    content: @icon-flag;
  }
}

.rs-icon-flag-checkered {
  &::before {
    content: @icon-flag-checkered;
  }
}

.rs-icon-flag-o {
  &::before {
    content: @icon-flag-o;
  }
}

.rs-icon-flask {
  &::before {
    content: @icon-flask;
  }
}

.rs-icon-flickr {
  &::before {
    content: @icon-flickr;
  }
}

.rs-icon-flow {
  &::before {
    content: @icon-flow;
  }
}

.rs-icon-folder {
  &::before {
    content: @icon-folder;
  }
}

.rs-icon-folder-o {
  &::before {
    content: @icon-folder-o;
  }
}

.rs-icon-folder-open {
  &::before {
    content: @icon-folder-open;
  }
}

.rs-icon-folder-open-o {
  &::before {
    content: @icon-folder-open-o;
  }
}

.rs-icon-font {
  &::before {
    content: @icon-font;
  }
}

.rs-icon-fonticons {
  &::before {
    content: @icon-fonticons;
  }
}

.rs-icon-fort-awesome {
  &::before {
    content: @icon-fort-awesome;
  }
}

.rs-icon-forumbee {
  &::before {
    content: @icon-forumbee;
  }
}

.rs-icon-forward {
  &::before {
    content: @icon-forward;
  }
}

.rs-icon-foursquare {
  &::before {
    content: @icon-foursquare;
  }
}

.rs-icon-frame {
  &::before {
    content: @icon-frame;
  }
}

.rs-icon-free-code-camp {
  &::before {
    content: @icon-free-code-camp;
  }
}

.rs-icon-frown-o {
  &::before {
    content: @icon-frown-o;
  }
}

.rs-icon-futbol-o {
  &::before {
    content: @icon-futbol-o;
  }
}

.rs-icon-gamepad {
  &::before {
    content: @icon-gamepad;
  }
}

.rs-icon-gavel {
  &::before {
    content: @icon-gavel;
  }
}

.rs-icon-gbp {
  &::before {
    content: @icon-gbp;
  }
}

.rs-icon-ge {
  &::before {
    content: @icon-ge;
  }
}

.rs-icon-gear {
  &::before {
    content: @icon-gear;
  }
}

.rs-icon-gear-circle {
  &::before {
    content: @icon-gear-circle;
  }
}

.rs-icon-gear2 {
  &::before {
    content: @icon-gear2;
  }
}

.rs-icon-gears2 {
  &::before {
    content: @icon-gears2;
  }
}

.rs-icon-genderless {
  &::before {
    content: @icon-genderless;
  }
}

.rs-icon-get-pocket {
  &::before {
    content: @icon-get-pocket;
  }
}

.rs-icon-gg {
  &::before {
    content: @icon-gg;
  }
}

.rs-icon-gg-circle {
  &::before {
    content: @icon-gg-circle;
  }
}

.rs-icon-gift {
  &::before {
    content: @icon-gift;
  }
}

.rs-icon-git {
  &::before {
    content: @icon-git;
  }
}

.rs-icon-git-square {
  &::before {
    content: @icon-git-square;
  }
}

.rs-icon-github {
  &::before {
    content: @icon-github;
  }
}

.rs-icon-github-alt {
  &::before {
    content: @icon-github-alt;
  }
}

.rs-icon-github-square {
  &::before {
    content: @icon-github-square;
  }
}

.rs-icon-gitlab {
  &::before {
    content: @icon-gitlab;
  }
}

.rs-icon-gittip {
  &::before {
    content: @icon-gittip;
  }
}

.rs-icon-glass {
  &::before {
    content: @icon-glass;
  }
}

.rs-icon-glide {
  &::before {
    content: @icon-glide;
  }
}

.rs-icon-glide-g {
  &::before {
    content: @icon-glide-g;
  }
}

.rs-icon-globe {
  &::before {
    content: @icon-globe;
  }
}

.rs-icon-globe2 {
  &::before {
    content: @icon-globe2;
  }
}

.rs-icon-good {
  &::before {
    content: @icon-good;
  }
}

.rs-icon-google {
  &::before {
    content: @icon-google;
  }
}

.rs-icon-google-plus {
  &::before {
    content: @icon-google-plus;
  }
}

.rs-icon-google-plus-circle {
  &::before {
    content: @icon-google-plus-circle;
  }
}

.rs-icon-google-plus-square {
  &::before {
    content: @icon-google-plus-square;
  }
}

.rs-icon-google-wallet {
  &::before {
    content: @icon-google-wallet;
  }
}

.rs-icon-grav {
  &::before {
    content: @icon-grav;
  }
}

.rs-icon-group {
  &::before {
    content: @icon-group;
  }
}

.rs-icon-h-square {
  &::before {
    content: @icon-h-square;
  }
}

.rs-icon-hand-grab-o {
  &::before {
    content: @icon-hand-grab-o;
  }
}

.rs-icon-hand-lizard-o {
  &::before {
    content: @icon-hand-lizard-o;
  }
}

.rs-icon-hand-o-down {
  &::before {
    content: @icon-hand-o-down;
  }
}

.rs-icon-hand-o-left {
  &::before {
    content: @icon-hand-o-left;
  }
}

.rs-icon-hand-o-right {
  &::before {
    content: @icon-hand-o-right;
  }
}

.rs-icon-hand-o-up {
  &::before {
    content: @icon-hand-o-up;
  }
}

.rs-icon-hand-peace-o {
  &::before {
    content: @icon-hand-peace-o;
  }
}

.rs-icon-hand-pointer-o {
  &::before {
    content: @icon-hand-pointer-o;
  }
}

.rs-icon-hand-scissors-o {
  &::before {
    content: @icon-hand-scissors-o;
  }
}

.rs-icon-hand-spock-o {
  &::before {
    content: @icon-hand-spock-o;
  }
}

.rs-icon-hand-stop-o {
  &::before {
    content: @icon-hand-stop-o;
  }
}

.rs-icon-handshake-o {
  &::before {
    content: @icon-handshake-o;
  }
}

.rs-icon-hashtag {
  &::before {
    content: @icon-hashtag;
  }
}

.rs-icon-hdd-o {
  &::before {
    content: @icon-hdd-o;
  }
}

.rs-icon-header {
  &::before {
    content: @icon-header;
  }
}

.rs-icon-headphones {
  &::before {
    content: @icon-headphones;
  }
}

.rs-icon-heart {
  &::before {
    content: @icon-heart;
  }
}

.rs-icon-heart-o {
  &::before {
    content: @icon-heart-o;
  }
}

.rs-icon-heartbeat {
  &::before {
    content: @icon-heartbeat;
  }
}

.rs-icon-help-o {
  &::before {
    content: @icon-help-o;
  }
}

.rs-icon-history {
  &::before {
    content: @icon-history;
  }
}

.rs-icon-home {
  &::before {
    content: @icon-home;
  }
}

.rs-icon-hospital-o {
  &::before {
    content: @icon-hospital-o;
  }
}

.rs-icon-hourglass {
  &::before {
    content: @icon-hourglass;
  }
}

.rs-icon-hourglass-1 {
  &::before {
    content: @icon-hourglass-1;
  }
}

.rs-icon-hourglass-2 {
  &::before {
    content: @icon-hourglass-2;
  }
}

.rs-icon-hourglass-3 {
  &::before {
    content: @icon-hourglass-3;
  }
}

.rs-icon-hourglass-o {
  &::before {
    content: @icon-hourglass-o;
  }
}

.rs-icon-houzz {
  &::before {
    content: @icon-houzz;
  }
}

.rs-icon-html5 {
  &::before {
    content: @icon-html5;
  }
}

.rs-icon-i-cursor {
  &::before {
    content: @icon-i-cursor;
  }
}

.rs-icon-id-badge {
  &::before {
    content: @icon-id-badge;
  }
}

.rs-icon-id-card {
  &::before {
    content: @icon-id-card;
  }
}

.rs-icon-id-card-o {
  &::before {
    content: @icon-id-card-o;
  }
}

.rs-icon-id-info {
  &::before {
    content: @icon-id-info;
  }
}

.rs-icon-id-mapping {
  &::before {
    content: @icon-id-mapping;
  }
}

.rs-icon-ils {
  &::before {
    content: @icon-ils;
  }
}

.rs-icon-image {
  &::before {
    content: @icon-image;
  }
}

.rs-icon-imdb {
  &::before {
    content: @icon-imdb;
  }
}

.rs-icon-import {
  &::before {
    content: @icon-import;
  }
}

.rs-icon-inbox {
  &::before {
    content: @icon-inbox;
  }
}

.rs-icon-indent {
  &::before {
    content: @icon-indent;
  }
}

.rs-icon-industry {
  &::before {
    content: @icon-industry;
  }
}

.rs-icon-info {
  &::before {
    content: @icon-info;
  }
}

.rs-icon-info-circle {
  &::before {
    content: @icon-info-circle;
  }
}

.rs-icon-inr {
  &::before {
    content: @icon-inr;
  }
}

.rs-icon-instagram {
  &::before {
    content: @icon-instagram;
  }
}

.rs-icon-internet-explorer {
  &::before {
    content: @icon-internet-explorer;
  }
}

.rs-icon-intersex {
  &::before {
    content: @icon-intersex;
  }
}

.rs-icon-ios {
  &::before {
    content: @icon-ios;
  }
}

.rs-icon-ioxhost {
  &::before {
    content: @icon-ioxhost;
  }
}

.rs-icon-italic {
  &::before {
    content: @icon-italic;
  }
}

.rs-icon-joomla {
  &::before {
    content: @icon-joomla;
  }
}

.rs-icon-jsfiddle {
  &::before {
    content: @icon-jsfiddle;
  }
}

.rs-icon-key {
  &::before {
    content: @icon-key;
  }
}

.rs-icon-keyboard-o {
  &::before {
    content: @icon-keyboard-o;
  }
}

.rs-icon-krw {
  &::before {
    content: @icon-krw;
  }
}

.rs-icon-language {
  &::before {
    content: @icon-language;
  }
}

.rs-icon-laptop {
  &::before {
    content: @icon-laptop;
  }
}

.rs-icon-lastfm {
  &::before {
    content: @icon-lastfm;
  }
}

.rs-icon-lastfm-square {
  &::before {
    content: @icon-lastfm-square;
  }
}

.rs-icon-leaf {
  &::before {
    content: @icon-leaf;
  }
}

.rs-icon-leanpub {
  &::before {
    content: @icon-leanpub;
  }
}

.rs-icon-left {
  &::before {
    content: @icon-left;
  }
}

.rs-icon-lemon-o {
  &::before {
    content: @icon-lemon-o;
  }
}

.rs-icon-level-down {
  &::before {
    content: @icon-level-down;
  }
}

.rs-icon-level-up {
  &::before {
    content: @icon-level-up;
  }
}

.rs-icon-lightbulb-o {
  &::before {
    content: @icon-lightbulb-o;
  }
}

.rs-icon-line-chart {
  &::before {
    content: @icon-line-chart;
  }
}

.rs-icon-link {
  &::before {
    content: @icon-link;
  }
}

.rs-icon-linkedin {
  &::before {
    content: @icon-linkedin;
  }
}

.rs-icon-linkedin-square {
  &::before {
    content: @icon-linkedin-square;
  }
}

.rs-icon-linode {
  &::before {
    content: @icon-linode;
  }
}

.rs-icon-linux {
  &::before {
    content: @icon-linux;
  }
}

.rs-icon-list {
  &::before {
    content: @icon-list;
  }
}

.rs-icon-list-alt {
  &::before {
    content: @icon-list-alt;
  }
}

.rs-icon-list-ol {
  &::before {
    content: @icon-list-ol;
  }
}

.rs-icon-list-ul {
  &::before {
    content: @icon-list-ul;
  }
}

.rs-icon-location-arrow {
  &::before {
    content: @icon-location-arrow;
  }
}

.rs-icon-lock {
  &::before {
    content: @icon-lock;
  }
}

.rs-icon-logo-ads {
  &::before {
    content: @icon-logo-ads;
  }
}

.rs-icon-logo-analytics {
  &::before {
    content: @icon-logo-analytics;
  }
}

.rs-icon-logo-dmp {
  &::before {
    content: @icon-logo-dmp;
  }
}

.rs-icon-logo-mobile {
  &::before {
    content: @icon-logo-mobile;
  }
}

.rs-icon-logo-shop {
  &::before {
    content: @icon-logo-shop;
  }
}

.rs-icon-logo-survey {
  &::before {
    content: @icon-logo-survey;
  }
}

.rs-icon-logo-video {
  &::before {
    content: @icon-logo-video;
  }
}

.rs-icon-long-arrow-down {
  &::before {
    content: @icon-long-arrow-down;
  }
}

.rs-icon-long-arrow-left {
  &::before {
    content: @icon-long-arrow-left;
  }
}

.rs-icon-long-arrow-right {
  &::before {
    content: @icon-long-arrow-right;
  }
}

.rs-icon-long-arrow-up {
  &::before {
    content: @icon-long-arrow-up;
  }
}

.rs-icon-low-vision {
  &::before {
    content: @icon-low-vision;
  }
}

.rs-icon-magic {
  &::before {
    content: @icon-magic;
  }
}

.rs-icon-magic2 {
  &::before {
    content: @icon-magic2;
  }
}

.rs-icon-magnet {
  &::before {
    content: @icon-magnet;
  }
}

.rs-icon-male {
  &::before {
    content: @icon-male;
  }
}

.rs-icon-map {
  &::before {
    content: @icon-map;
  }
}

.rs-icon-map-marker {
  &::before {
    content: @icon-map-marker;
  }
}

.rs-icon-map-o {
  &::before {
    content: @icon-map-o;
  }
}

.rs-icon-map-pin {
  &::before {
    content: @icon-map-pin;
  }
}

.rs-icon-map-signs {
  &::before {
    content: @icon-map-signs;
  }
}

.rs-icon-mars {
  &::before {
    content: @icon-mars;
  }
}

.rs-icon-mars-double {
  &::before {
    content: @icon-mars-double;
  }
}

.rs-icon-mars-stroke {
  &::before {
    content: @icon-mars-stroke;
  }
}

.rs-icon-mars-stroke-h {
  &::before {
    content: @icon-mars-stroke-h;
  }
}

.rs-icon-mars-stroke-v {
  &::before {
    content: @icon-mars-stroke-v;
  }
}

.rs-icon-maxcdn {
  &::before {
    content: @icon-maxcdn;
  }
}

.rs-icon-meanpath {
  &::before {
    content: @icon-meanpath;
  }
}

.rs-icon-medium {
  &::before {
    content: @icon-medium;
  }
}

.rs-icon-medkit {
  &::before {
    content: @icon-medkit;
  }
}

.rs-icon-meetup {
  &::before {
    content: @icon-meetup;
  }
}

.rs-icon-meh-o {
  &::before {
    content: @icon-meh-o;
  }
}

.rs-icon-mercury {
  &::before {
    content: @icon-mercury;
  }
}

.rs-icon-microchip {
  &::before {
    content: @icon-microchip;
  }
}

.rs-icon-microphone {
  &::before {
    content: @icon-microphone;
  }
}

.rs-icon-microphone-slash {
  &::before {
    content: @icon-microphone-slash;
  }
}

.rs-icon-minus {
  &::before {
    content: @icon-minus;
  }
}

.rs-icon-minus-circle {
  &::before {
    content: @icon-minus-circle;
  }
}

.rs-icon-minus-square {
  &::before {
    content: @icon-minus-square;
  }
}

.rs-icon-minus-square-o {
  &::before {
    content: @icon-minus-square-o;
  }
}

.rs-icon-mixcloud {
  &::before {
    content: @icon-mixcloud;
  }
}

.rs-icon-mobile {
  &::before {
    content: @icon-mobile;
  }
}

.rs-icon-modx {
  &::before {
    content: @icon-modx;
  }
}

.rs-icon-money {
  &::before {
    content: @icon-money;
  }
}

.rs-icon-moon-o {
  &::before {
    content: @icon-moon-o;
  }
}

.rs-icon-more {
  &::before {
    content: @icon-more;
  }
}

.rs-icon-mortar-board {
  &::before {
    content: @icon-mortar-board;
  }
}

.rs-icon-motorcycle {
  &::before {
    content: @icon-motorcycle;
  }
}

.rs-icon-mouse-pointer {
  &::before {
    content: @icon-mouse-pointer;
  }
}

.rs-icon-multiple-lines-chart {
  &::before {
    content: @icon-multiple-lines-chart;
  }
}

.rs-icon-music {
  &::before {
    content: @icon-music;
  }
}

.rs-icon-neuter {
  &::before {
    content: @icon-neuter;
  }
}

.rs-icon-newspaper-o {
  &::before {
    content: @icon-newspaper-o;
  }
}

.rs-icon-object-group {
  &::before {
    content: @icon-object-group;
  }
}

.rs-icon-object-ungroup {
  &::before {
    content: @icon-object-ungroup;
  }
}

.rs-icon-odnoklassniki {
  &::before {
    content: @icon-odnoklassniki;
  }
}

.rs-icon-odnoklassniki-square {
  &::before {
    content: @icon-odnoklassniki-square;
  }
}

.rs-icon-off {
  &::before {
    content: @icon-off;
  }
}

.rs-icon-ok-circle {
  &::before {
    content: @icon-ok-circle;
  }
}

.rs-icon-opencart {
  &::before {
    content: @icon-opencart;
  }
}

.rs-icon-openid {
  &::before {
    content: @icon-openid;
  }
}

.rs-icon-opera {
  &::before {
    content: @icon-opera;
  }
}

.rs-icon-optin-monster {
  &::before {
    content: @icon-optin-monster;
  }
}

.rs-icon-order-form {
  &::before {
    content: @icon-order-form;
  }
}

.rs-icon-page-end {
  &::before {
    content: @icon-page-end;
  }
}

.rs-icon-page-next {
  &::before {
    content: @icon-page-next;
  }
}

.rs-icon-page-previous {
  &::before {
    content: @icon-page-previous;
  }
}

.rs-icon-page-top {
  &::before {
    content: @icon-page-top;
  }
}

.rs-icon-pagelines {
  &::before {
    content: @icon-pagelines;
  }
}

.rs-icon-paint-brush {
  &::before {
    content: @icon-paint-brush;
  }
}

.rs-icon-paperclip {
  &::before {
    content: @icon-paperclip;
  }
}

.rs-icon-paragraph {
  &::before {
    content: @icon-paragraph;
  }
}

.rs-icon-paste {
  &::before {
    content: @icon-paste;
  }
}

.rs-icon-pause {
  &::before {
    content: @icon-pause;
  }
}

.rs-icon-pause-circle {
  &::before {
    content: @icon-pause-circle;
  }
}

.rs-icon-pause-circle-o {
  &::before {
    content: @icon-pause-circle-o;
  }
}

.rs-icon-paw {
  &::before {
    content: @icon-paw;
  }
}

.rs-icon-paypal {
  &::before {
    content: @icon-paypal;
  }
}

.rs-icon-pc {
  &::before {
    content: @icon-pc;
  }
}

.rs-icon-pencil {
  &::before {
    content: @icon-pencil;
  }
}

.rs-icon-pencil-square {
  &::before {
    content: @icon-pencil-square;
  }
}

.rs-icon-people-group {
  &::before {
    content: @icon-people-group;
  }
}

.rs-icon-peoples {
  &::before {
    content: @icon-peoples;
  }
}

.rs-icon-peoples-map {
  &::before {
    content: @icon-peoples-map;
  }
}

.rs-icon-percent {
  &::before {
    content: @icon-percent;
  }
}

.rs-icon-phone {
  &::before {
    content: @icon-phone;
  }
}

.rs-icon-phone-square {
  &::before {
    content: @icon-phone-square;
  }
}

.rs-icon-pie-chart {
  &::before {
    content: @icon-pie-chart;
  }
}

.rs-icon-pied-piper {
  &::before {
    content: @icon-pied-piper;
  }
}

.rs-icon-pied-piper-alt {
  &::before {
    content: @icon-pied-piper-alt;
  }
}

.rs-icon-pied-piper-pp {
  &::before {
    content: @icon-pied-piper-pp;
  }
}

.rs-icon-pinterest {
  &::before {
    content: @icon-pinterest;
  }
}

.rs-icon-pinterest-p {
  &::before {
    content: @icon-pinterest-p;
  }
}

.rs-icon-pinterest-square {
  &::before {
    content: @icon-pinterest-square;
  }
}

.rs-icon-plane {
  &::before {
    content: @icon-plane;
  }
}

.rs-icon-play {
  &::before {
    content: @icon-play;
  }
}

.rs-icon-play-circle {
  &::before {
    content: @icon-play-circle;
  }
}

.rs-icon-play-circle-o {
  &::before {
    content: @icon-play-circle-o;
  }
}

.rs-icon-play2 {
  &::before {
    content: @icon-play2;
  }
}

.rs-icon-plug {
  &::before {
    content: @icon-plug;
  }
}

.rs-icon-plus {
  &::before {
    content: @icon-plus;
  }
}

.rs-icon-plus-circle {
  &::before {
    content: @icon-plus-circle;
  }
}

.rs-icon-plus-square {
  &::before {
    content: @icon-plus-square;
  }
}

.rs-icon-plus-square-o {
  &::before {
    content: @icon-plus-square-o;
  }
}

.rs-icon-podcast {
  &::before {
    content: @icon-podcast;
  }
}

.rs-icon-power-off {
  &::before {
    content: @icon-power-off;
  }
}

.rs-icon-print {
  &::before {
    content: @icon-print;
  }
}

.rs-icon-product-hunt {
  &::before {
    content: @icon-product-hunt;
  }
}

.rs-icon-profile {
  &::before {
    content: @icon-profile;
  }
}

.rs-icon-project {
  &::before {
    content: @icon-project;
  }
}

.rs-icon-public-opinion {
  &::before {
    content: @icon-public-opinion;
  }
}

.rs-icon-puzzle-piece {
  &::before {
    content: @icon-puzzle-piece;
  }
}

.rs-icon-qq {
  &::before {
    content: @icon-qq;
  }
}

.rs-icon-qrcode {
  &::before {
    content: @icon-qrcode;
  }
}

.rs-icon-question {
  &::before {
    content: @icon-question;
  }
}

.rs-icon-question-circle {
  &::before {
    content: @icon-question-circle;
  }
}

.rs-icon-question-circle2 {
  &::before {
    content: @icon-question-circle2;
  }
}

.rs-icon-question2 {
  &::before {
    content: @icon-question2;
  }
}

.rs-icon-quora {
  &::before {
    content: @icon-quora;
  }
}

.rs-icon-quote-left {
  &::before {
    content: @icon-quote-left;
  }
}

.rs-icon-quote-right {
  &::before {
    content: @icon-quote-right;
  }
}

.rs-icon-ra {
  &::before {
    content: @icon-ra;
  }
}

.rs-icon-random {
  &::before {
    content: @icon-random;
  }
}

.rs-icon-rate {
  &::before {
    content: @icon-rate;
  }
}

.rs-icon-ravelry {
  &::before {
    content: @icon-ravelry;
  }
}

.rs-icon-realtime {
  &::before {
    content: @icon-realtime;
  }
}

.rs-icon-recycle {
  &::before {
    content: @icon-recycle;
  }
}

.rs-icon-reddit {
  &::before {
    content: @icon-reddit;
  }
}

.rs-icon-reddit-alien {
  &::before {
    content: @icon-reddit-alien;
  }
}

.rs-icon-reddit-square {
  &::before {
    content: @icon-reddit-square;
  }
}

.rs-icon-refresh {
  &::before {
    content: @icon-refresh;
  }
}

.rs-icon-refresh2 {
  &::before {
    content: @icon-refresh2;
  }
}

.rs-icon-registered {
  &::before {
    content: @icon-registered;
  }
}

.rs-icon-related-map {
  &::before {
    content: @icon-related-map;
  }
}

.rs-icon-reload {
  &::before {
    content: @icon-reload;
  }
}

.rs-icon-remind {
  &::before {
    content: @icon-remind;
  }
}

.rs-icon-renren {
  &::before {
    content: @icon-renren;
  }
}

.rs-icon-repeat {
  &::before {
    content: @icon-repeat;
  }
}

.rs-icon-reply {
  &::before {
    content: @icon-reply;
  }
}

.rs-icon-reply-all {
  &::before {
    content: @icon-reply-all;
  }
}

.rs-icon-retention {
  &::before {
    content: @icon-retention;
  }
}

.rs-icon-retweet {
  &::before {
    content: @icon-retweet;
  }
}

.rs-icon-right {
  &::before {
    content: @icon-right;
  }
}

.rs-icon-road {
  &::before {
    content: @icon-road;
  }
}

.rs-icon-rocket {
  &::before {
    content: @icon-rocket;
  }
}

.rs-icon-rss {
  &::before {
    content: @icon-rss;
  }
}

.rs-icon-rss-square {
  &::before {
    content: @icon-rss-square;
  }
}

.rs-icon-rub {
  &::before {
    content: @icon-rub;
  }
}

.rs-icon-s15 {
  &::before {
    content: @icon-s15;
  }
}

.rs-icon-safari {
  &::before {
    content: @icon-safari;
  }
}

.rs-icon-sales {
  &::before {
    content: @icon-sales;
  }
}

.rs-icon-growth {
  &::before {
    content: @icon-growth;
  }
}

.rs-icon-save {
  &::before {
    content: @icon-save;
  }
}

.rs-icon-scribd {
  &::before {
    content: @icon-scribd;
  }
}

.rs-icon-search {
  &::before {
    content: @icon-search;
  }
}

.rs-icon-search-minus {
  &::before {
    content: @icon-search-minus;
  }
}

.rs-icon-search-peoples {
  &::before {
    content: @icon-search-peoples;
  }
}

.rs-icon-search-plus {
  &::before {
    content: @icon-search-plus;
  }
}

.rs-icon-sellsy {
  &::before {
    content: @icon-sellsy;
  }
}

.rs-icon-send {
  &::before {
    content: @icon-send;
  }
}

.rs-icon-send-o {
  &::before {
    content: @icon-send-o;
  }
}

.rs-icon-sequence {
  &::before {
    content: @icon-sequence;
  }
}

.rs-icon-sequence-down {
  &::before {
    content: @icon-sequence-down;
  }
}

.rs-icon-sequence-up {
  &::before {
    content: @icon-sequence-up;
  }
}

.rs-icon-server {
  &::before {
    content: @icon-server;
  }
}

.rs-icon-setting {
  &::before {
    content: @icon-setting;
  }
}

.rs-icon-shapes {
  &::before {
    content: @icon-shapes;
  }
}

.rs-icon-share {
  &::before {
    content: @icon-share;
  }
}

.rs-icon-share-alt {
  &::before {
    content: @icon-share-alt;
  }
}

.rs-icon-share-alt-square {
  &::before {
    content: @icon-share-alt-square;
  }
}

.rs-icon-share-square {
  &::before {
    content: @icon-share-square;
  }
}

.rs-icon-share-square-o {
  &::before {
    content: @icon-share-square-o;
  }
}

.rs-icon-share2 {
  &::before {
    content: @icon-share2;
  }
}

.rs-icon-shield {
  &::before {
    content: @icon-shield;
  }
}

.rs-icon-ship {
  &::before {
    content: @icon-ship;
  }
}

.rs-icon-shirtsinbulk {
  &::before {
    content: @icon-shirtsinbulk;
  }
}

.rs-icon-shopping-bag {
  &::before {
    content: @icon-shopping-bag;
  }
}

.rs-icon-shopping-basket {
  &::before {
    content: @icon-shopping-basket;
  }
}

.rs-icon-shopping-cart {
  &::before {
    content: @icon-shopping-cart;
  }
}

.rs-icon-shower {
  &::before {
    content: @icon-shower;
  }
}

.rs-icon-sign-in {
  &::before {
    content: @icon-sign-in;
  }
}

.rs-icon-sign-out {
  &::before {
    content: @icon-sign-out;
  }
}

.rs-icon-signal {
  &::before {
    content: @icon-signal;
  }
}

.rs-icon-signing {
  &::before {
    content: @icon-signing;
  }
}

.rs-icon-simplybuilt {
  &::before {
    content: @icon-simplybuilt;
  }
}

.rs-icon-sitemap {
  &::before {
    content: @icon-sitemap;
  }
}

.rs-icon-skyatlas {
  &::before {
    content: @icon-skyatlas;
  }
}

.rs-icon-skype {
  &::before {
    content: @icon-skype;
  }
}

.rs-icon-slack {
  &::before {
    content: @icon-slack;
  }
}

.rs-icon-sliders {
  &::before {
    content: @icon-sliders;
  }
}

.rs-icon-slideshare {
  &::before {
    content: @icon-slideshare;
  }
}

.rs-icon-smile-o {
  &::before {
    content: @icon-smile-o;
  }
}

.rs-icon-snapchat {
  &::before {
    content: @icon-snapchat;
  }
}

.rs-icon-snapchat-ghost {
  &::before {
    content: @icon-snapchat-ghost;
  }
}

.rs-icon-snapchat-square {
  &::before {
    content: @icon-snapchat-square;
  }
}

.rs-icon-snowflake-o {
  &::before {
    content: @icon-snowflake-o;
  }
}

.rs-icon-sort {
  &::before {
    content: @icon-sort;
  }
}

.rs-icon-sort-alpha-asc {
  &::before {
    content: @icon-sort-alpha-asc;
  }
}

.rs-icon-sort-alpha-desc {
  &::before {
    content: @icon-sort-alpha-desc;
  }
}

.rs-icon-sort-amount-asc {
  &::before {
    content: @icon-sort-amount-asc;
  }
}

.rs-icon-sort-amount-desc {
  &::before {
    content: @icon-sort-amount-desc;
  }
}

.rs-icon-sort-desc {
  &::before {
    content: @icon-sort-desc;
  }
}

.rs-icon-sort-numeric-asc {
  &::before {
    content: @icon-sort-numeric-asc;
  }
}

.rs-icon-sort-numeric-desc {
  &::before {
    content: @icon-sort-numeric-desc;
  }
}

.rs-icon-sort-up {
  &::before {
    content: @icon-sort-up;
  }
}

.rs-icon-soundcloud {
  &::before {
    content: @icon-soundcloud;
  }
}

.rs-icon-space-shuttle {
  &::before {
    content: @icon-space-shuttle;
  }
}

.rs-icon-speaker {
  &::before {
    content: @icon-speaker;
  }
}

.rs-icon-spinner {
  &::before {
    content: @icon-spinner;
  }
}

.rs-icon-spoon {
  &::before {
    content: @icon-spoon;
  }
}

.rs-icon-spotify {
  &::before {
    content: @icon-spotify;
  }
}

.rs-icon-square {
  &::before {
    content: @icon-square;
  }
}

.rs-icon-square-o {
  &::before {
    content: @icon-square-o;
  }
}

.rs-icon-squares {
  &::before {
    content: @icon-squares;
  }
}

.rs-icon-stack-exchange {
  &::before {
    content: @icon-stack-exchange;
  }
}

.rs-icon-stack-overflow {
  &::before {
    content: @icon-stack-overflow;
  }
}

.rs-icon-star {
  &::before {
    content: @icon-star;
  }
}

.rs-icon-star-half {
  &::before {
    content: @icon-star-half;
  }
}

.rs-icon-star-half-o {
  &::before {
    content: @icon-star-half-o;
  }
}

.rs-icon-star-o {
  &::before {
    content: @icon-star-o;
  }
}

.rs-icon-steam {
  &::before {
    content: @icon-steam;
  }
}

.rs-icon-steam-square {
  &::before {
    content: @icon-steam-square;
  }
}

.rs-icon-step-backward {
  &::before {
    content: @icon-step-backward;
  }
}

.rs-icon-step-forward {
  &::before {
    content: @icon-step-forward;
  }
}

.rs-icon-stethoscope {
  &::before {
    content: @icon-stethoscope;
  }
}

.rs-icon-sticky-note {
  &::before {
    content: @icon-sticky-note;
  }
}

.rs-icon-sticky-note-o {
  &::before {
    content: @icon-sticky-note-o;
  }
}

.rs-icon-stop {
  &::before {
    content: @icon-stop;
  }
}

.rs-icon-stop-circle {
  &::before {
    content: @icon-stop-circle;
  }
}

.rs-icon-stop-circle-o {
  &::before {
    content: @icon-stop-circle-o;
  }
}

.rs-icon-stop2 {
  &::before {
    content: @icon-stop2;
  }
}

.rs-icon-street-view {
  &::before {
    content: @icon-street-view;
  }
}

.rs-icon-strikethrough {
  &::before {
    content: @icon-strikethrough;
  }
}

.rs-icon-stumbleupon {
  &::before {
    content: @icon-stumbleupon;
  }
}

.rs-icon-stumbleupon-circle {
  &::before {
    content: @icon-stumbleupon-circle;
  }
}

.rs-icon-subscript {
  &::before {
    content: @icon-subscript;
  }
}

.rs-icon-subway {
  &::before {
    content: @icon-subway;
  }
}

.rs-icon-suitcase {
  &::before {
    content: @icon-suitcase;
  }
}

.rs-icon-sun-o {
  &::before {
    content: @icon-sun-o;
  }
}

.rs-icon-superpowers {
  &::before {
    content: @icon-superpowers;
  }
}

.rs-icon-superscript {
  &::before {
    content: @icon-superscript;
  }
}

.rs-icon-support {
  &::before {
    content: @icon-support;
  }
}

.rs-icon-table {
  &::before {
    content: @icon-table;
  }
}

.rs-icon-tablet {
  &::before {
    content: @icon-tablet;
  }
}

.rs-icon-tag {
  &::before {
    content: @icon-tag;
  }
}

.rs-icon-tag-area {
  &::before {
    content: @icon-tag-area;
  }
}

.rs-icon-tag-authorize {
  &::before {
    content: @icon-tag-authorize;
  }
}

.rs-icon-tag-unauthorize {
  &::before {
    content: @icon-tag-unauthorize;
  }
}

.rs-icon-tags {
  &::before {
    content: @icon-tags;
  }
}

.rs-icon-target {
  &::before {
    content: @icon-target;
  }
}

.rs-icon-task {
  &::before {
    content: @icon-task;
  }
}

.rs-icon-tasks {
  &::before {
    content: @icon-tasks;
  }
}

.rs-icon-telegram {
  &::before {
    content: @icon-telegram;
  }
}

.rs-icon-tencent-weibo {
  &::before {
    content: @icon-tencent-weibo;
  }
}

.rs-icon-terminal {
  &::before {
    content: @icon-terminal;
  }
}

.rs-icon-terminal-line {
  &::before {
    content: @icon-terminal-line;
  }
}

.rs-icon-text-height {
  &::before {
    content: @icon-text-height;
  }
}

.rs-icon-text-width {
  &::before {
    content: @icon-text-width;
  }
}

.rs-icon-th {
  &::before {
    content: @icon-th;
  }
}

.rs-icon-th-large {
  &::before {
    content: @icon-th-large;
  }
}

.rs-icon-th-list {
  &::before {
    content: @icon-th-list;
  }
}

.rs-icon-th2 {
  &::before {
    content: @icon-th2;
  }
}

.rs-icon-themeisle {
  &::before {
    content: @icon-themeisle;
  }
}

.rs-icon-thermometer {
  &::before {
    content: @icon-thermometer;
  }
}

.rs-icon-thermometer-0 {
  &::before {
    content: @icon-thermometer-0;
  }
}

.rs-icon-thermometer-1 {
  &::before {
    content: @icon-thermometer-1;
  }
}

.rs-icon-thermometer-2 {
  &::before {
    content: @icon-thermometer-2;
  }
}

.rs-icon-thermometer-3 {
  &::before {
    content: @icon-thermometer-3;
  }
}

.rs-icon-thumb-tack {
  &::before {
    content: @icon-thumb-tack;
  }
}

.rs-icon-thumbs-down {
  &::before {
    content: @icon-thumbs-down;
  }
}

.rs-icon-thumbs-o-down {
  &::before {
    content: @icon-thumbs-o-down;
  }
}

.rs-icon-thumbs-o-up {
  &::before {
    content: @icon-thumbs-o-up;
  }
}

.rs-icon-thumbs-up {
  &::before {
    content: @icon-thumbs-up;
  }
}

.rs-icon-ticket {
  &::before {
    content: @icon-ticket;
  }
}

.rs-icon-times-circle {
  &::before {
    content: @icon-times-circle;
  }
}

.rs-icon-times-circle-o {
  &::before {
    content: @icon-times-circle-o;
  }
}

.rs-icon-tint {
  &::before {
    content: @icon-tint;
  }
}

.rs-icon-tmall {
  &::before {
    content: @icon-tmall;
  }
}

.rs-icon-toggle-down {
  &::before {
    content: @icon-toggle-down;
  }
}

.rs-icon-toggle-left {
  &::before {
    content: @icon-toggle-left;
  }
}

.rs-icon-toggle-off {
  &::before {
    content: @icon-toggle-off;
  }
}

.rs-icon-toggle-on {
  &::before {
    content: @icon-toggle-on;
  }
}

.rs-icon-toggle-right {
  &::before {
    content: @icon-toggle-right;
  }
}

.rs-icon-toggle-up {
  &::before {
    content: @icon-toggle-up;
  }
}

.rs-icon-trademark {
  &::before {
    content: @icon-trademark;
  }
}

.rs-icon-train {
  &::before {
    content: @icon-train;
  }
}

.rs-icon-transgender-alt {
  &::before {
    content: @icon-transgender-alt;
  }
}

.rs-icon-trash {
  &::before {
    content: @icon-trash;
  }
}

.rs-icon-trash-o {
  &::before {
    content: @icon-trash-o;
  }
}

.rs-icon-trash2 {
  &::before {
    content: @icon-trash2;
  }
}

.rs-icon-tree {
  &::before {
    content: @icon-tree;
  }
}

.rs-icon-tree-close {
  &::before {
    content: @icon-tree-close;
  }
}

.rs-icon-tree-open {
  &::before {
    content: @icon-tree-open;
  }
}

.rs-icon-trello {
  &::before {
    content: @icon-trello;
  }
}

.rs-icon-trend {
  &::before {
    content: @icon-trend;
  }
}

.rs-icon-tripadvisor {
  &::before {
    content: @icon-tripadvisor;
  }
}

.rs-icon-trophy {
  &::before {
    content: @icon-trophy;
  }
}

.rs-icon-truck {
  &::before {
    content: @icon-truck;
  }
}

.rs-icon-try {
  &::before {
    content: @icon-try;
  }
}

.rs-icon-tty {
  &::before {
    content: @icon-tty;
  }
}

.rs-icon-tumblr {
  &::before {
    content: @icon-tumblr;
  }
}

.rs-icon-tumblr-square {
  &::before {
    content: @icon-tumblr-square;
  }
}

.rs-icon-tv {
  &::before {
    content: @icon-tv;
  }
}

.rs-icon-twinkle-star {
  &::before {
    content: @icon-twinkle-star;
  }
}

.rs-icon-twitch {
  &::before {
    content: @icon-twitch;
  }
}

.rs-icon-twitter {
  &::before {
    content: @icon-twitter;
  }
}

.rs-icon-twitter-square {
  &::before {
    content: @icon-twitter-square;
  }
}

.rs-icon-umbrella {
  &::before {
    content: @icon-umbrella;
  }
}

.rs-icon-underline {
  &::before {
    content: @icon-underline;
  }
}

.rs-icon-undo {
  &::before {
    content: @icon-undo;
  }
}

.rs-icon-universal-access {
  &::before {
    content: @icon-universal-access;
  }
}

.rs-icon-unlink {
  &::before {
    content: @icon-unlink;
  }
}

.rs-icon-unlock {
  &::before {
    content: @icon-unlock;
  }
}

.rs-icon-unlock-alt {
  &::before {
    content: @icon-unlock-alt;
  }
}

.rs-icon-up {
  &::before {
    content: @icon-up;
  }
}

.rs-icon-upload {
  &::before {
    content: @icon-upload;
  }
}

.rs-icon-upload2 {
  &::before {
    content: @icon-upload2;
  }
}

.rs-icon-usb {
  &::before {
    content: @icon-usb;
  }
}

.rs-icon-usd {
  &::before {
    content: @icon-usd;
  }
}

.rs-icon-user {
  &::before {
    content: @icon-user;
  }
}

.rs-icon-user-analysis {
  &::before {
    content: @icon-user-analysis;
  }
}

.rs-icon-user-circle {
  &::before {
    content: @icon-user-circle;
  }
}

.rs-icon-user-circle-o {
  &::before {
    content: @icon-user-circle-o;
  }
}

.rs-icon-user-info {
  &::before {
    content: @icon-user-info;
  }
}

.rs-icon-user-md {
  &::before {
    content: @icon-user-md;
  }
}

.rs-icon-user-o {
  &::before {
    content: @icon-user-o;
  }
}

.rs-icon-user-plus {
  &::before {
    content: @icon-user-plus;
  }
}

.rs-icon-user-secret {
  &::before {
    content: @icon-user-secret;
  }
}

.rs-icon-user-times {
  &::before {
    content: @icon-user-times;
  }
}

.rs-icon-vcard {
  &::before {
    content: @icon-vcard;
  }
}

.rs-icon-vcard-o {
  &::before {
    content: @icon-vcard-o;
  }
}

.rs-icon-venus {
  &::before {
    content: @icon-venus;
  }
}

.rs-icon-venus-double {
  &::before {
    content: @icon-venus-double;
  }
}

.rs-icon-venus-mars {
  &::before {
    content: @icon-venus-mars;
  }
}

.rs-icon-viacoin {
  &::before {
    content: @icon-viacoin;
  }
}

.rs-icon-viadeo {
  &::before {
    content: @icon-viadeo;
  }
}

.rs-icon-viadeo-square {
  &::before {
    content: @icon-viadeo-square;
  }
}

.rs-icon-video-camera {
  &::before {
    content: @icon-video-camera;
  }
}

.rs-icon-views-authorize {
  &::before {
    content: @icon-views-authorize;
  }
}

.rs-icon-views-unauthorize {
  &::before {
    content: @icon-views-unauthorize;
  }
}

.rs-icon-vimeo {
  &::before {
    content: @icon-vimeo;
  }
}

.rs-icon-vimeo-square {
  &::before {
    content: @icon-vimeo-square;
  }
}

.rs-icon-vine {
  &::before {
    content: @icon-vine;
  }
}

.rs-icon-vk {
  &::before {
    content: @icon-vk;
  }
}

.rs-icon-volume-control-phone {
  &::before {
    content: @icon-volume-control-phone;
  }
}

.rs-icon-volume-down {
  &::before {
    content: @icon-volume-down;
  }
}

.rs-icon-volume-off {
  &::before {
    content: @icon-volume-off;
  }
}

.rs-icon-volume-up {
  &::before {
    content: @icon-volume-up;
  }
}

.rs-icon-warning {
  &::before {
    content: @icon-warning;
  }
}

.rs-icon-weapp {
  &::before {
    content: @icon-weapp;
  }
}

.rs-icon-web {
  &::before {
    content: @icon-web;
  }
}

.rs-icon-wechat {
  &::before {
    content: @icon-wechat;
  }
}

.rs-icon-weibo {
  &::before {
    content: @icon-weibo;
  }
}

.rs-icon-whatsapp {
  &::before {
    content: @icon-whatsapp;
  }
}

.rs-icon-wheelchair {
  &::before {
    content: @icon-wheelchair;
  }
}

.rs-icon-wheelchair-alt {
  &::before {
    content: @icon-wheelchair-alt;
  }
}

.rs-icon-wifi {
  &::before {
    content: @icon-wifi;
  }
}

.rs-icon-wikipedia-w {
  &::before {
    content: @icon-wikipedia-w;
  }
}

.rs-icon-window-close {
  &::before {
    content: @icon-window-close;
  }
}

.rs-icon-window-close-o {
  &::before {
    content: @icon-window-close-o;
  }
}

.rs-icon-window-maximize {
  &::before {
    content: @icon-window-maximize;
  }
}

.rs-icon-window-minimize {
  &::before {
    content: @icon-window-minimize;
  }
}

.rs-icon-window-restore {
  &::before {
    content: @icon-window-restore;
  }
}

.rs-icon-windows {
  &::before {
    content: @icon-windows;
  }
}

.rs-icon-wordpress {
  &::before {
    content: @icon-wordpress;
  }
}

.rs-icon-wpbeginner {
  &::before {
    content: @icon-wpbeginner;
  }
}

.rs-icon-wpexplorer {
  &::before {
    content: @icon-wpexplorer;
  }
}

.rs-icon-wpforms {
  &::before {
    content: @icon-wpforms;
  }
}

.rs-icon-wrench {
  &::before {
    content: @icon-wrench;
  }
}

.rs-icon-xing {
  &::before {
    content: @icon-xing;
  }
}

.rs-icon-xing-square {
  &::before {
    content: @icon-xing-square;
  }
}

.rs-icon-yahoo {
  &::before {
    content: @icon-yahoo;
  }
}

.rs-icon-yc {
  &::before {
    content: @icon-yc;
  }
}

.rs-icon-yc-square {
  &::before {
    content: @icon-yc-square;
  }
}

.rs-icon-yelp {
  &::before {
    content: @icon-yelp;
  }
}

.rs-icon-yoast {
  &::before {
    content: @icon-yoast;
  }
}

.rs-icon-youtube {
  &::before {
    content: @icon-youtube;
  }
}

.rs-icon-youtube-play {
  &::before {
    content: @icon-youtube-play;
  }
}

.rs-icon-youtube-square {
  &::before {
    content: @icon-youtube-square;
  }
}
