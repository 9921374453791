@import 'common';
@import 'mixins/radio';

.@{ns}radio-checker {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: @radio-sense-width * 2 + @radio-width-height;
  min-height: @radio-sense-width * 2 + @radio-width-height;
  line-height: 1;
  position: relative;
}

.@{ns}radio-wrapper {
  position: absolute;
  width: @radio-width-height;
  height: @radio-width-height;
  display: inline-block;
  left: @radio-sense-width;
  top: @checkbox-sense-width;

  [type='radio'] {
    display: none;
  }

  &::before,
  &::after,
  .@{ns}radio-inner::before,
  .@{ns}radio-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &::before,
  .@{ns}radio-inner::before,
  .@{ns}radio-inner::after {
    width: @radio-width-height;
    height: @radio-width-height;
  }

  // Ripple effect area.
  &::before {
    .radio-inner-wrapper(@radio-checked-border-color);

    transform: scale(1);
    opacity: 0.7;
    visibility: hidden;
    transition: transform 0.2s linear, opacity 0.2s linear;

    .@{ns}radio-checked & {
      transform: scale(1.5);
      opacity: 0;
      visibility: visible;
    }
  }

  // Sense area(Can be clicked).
  &::after {
    top: -@radio-sense-width;
    right: -@radio-sense-width;
    bottom: -@radio-sense-width;
    left: -@radio-sense-width;
  }

  // Out border.
  .@{ns}radio-inner::before {
    .radio-inner-wrapper(@radio-default-border-color);

    transition: background 0.2s linear, border 0.2s linear, box-shadow 0.2s linear;

    .@{ns}radio-checked & {
      border-color: @radio-checked-border-color;
      background-color: @radio-checked-border-color;
    }

    .@{ns}radio-disabled:not(.@{ns}radio-checked) & {
      border-color: @radio-disabled-color;
      background-color: @radio-disabled-color;
    }

    .@{ns}radio-disabled.@{ns}radio-checked & {
      opacity: 0.3;
    }
  }

  // Solid circle.
  .@{ns}radio-inner::after {
    width: @radio-inner-width-height;
    height: @radio-inner-width-height;
    background: @radio-inner-checked-color;
    margin-top: (@radio-width-height - @radio-inner-width-height) / 2;
    margin-left: (@radio-width-height - @radio-inner-width-height) / 2;
    border-radius: @radio-border-radius;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s ease-in, transform 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46);

    .@{ns}radio-checked & {
      transform: scale(1);
      opacity: 1;
    }
  }

  //Disabled radio can't focus
  .@{ns}radio:not(.@{ns}radio-disabled) &:focus {
    outline: none;

    .@{ns}radio-inner::before {
      .radio-inner-active-shadow(@radio-default-border-color);

      @hover: ~':hover';

      @{hover}& {
        .radio-inner-active-shadow(@radio-checked-border-color);
      }

      .@{ns}radio-checked & {
        .radio-inner-active-shadow(@radio-checked-border-color);
      }
    }
  }
}
