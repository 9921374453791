.reset-sidenav-dropdown-menu() {
  display: block;
  position: static;
  float: none;
  box-shadow: none;
  animation-name: none;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
}
