@import 'common';
@import 'mixins/checkbox';
@import 'mixins/tree-picker';

// Check Tree Picker
// ----------------------

// Check tree picker name space
@ctpns: ~'@{ns}@{check-tree-picker-prefix}';

.@{ctpns}-view {
  background-color: @picker-tree-bg;
  max-height: 360px;
  overflow-y: auto;
}

.@{ctpns}-view-node {
  position: relative;
  font-size: @picker-tree-node-font-size;
  line-height: @picker-tree-node-line-height;

  > .@{ctpns}-view-checknode-label {
    margin: 0;
    //padding-left: 16px;
    padding: @picker-tree-node-padding-vertical @picker-tree-node-padding-horizontal;
    //text gap + checkbox space
    padding-left: 58px; // 10px + 36px + 12px
    display: inline-block;
    cursor: pointer;
    position: relative;

    // Only has 1 level
    .without-children & {
      padding-left: 34px; //text gap + checkbox space

      &::before {
        width: 28px;
        margin-left: -36px;
      }

      .@{ctpns}-view-input-wrapper {
        left: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: @picker-tree-arrow-down-width+ @picker-tree-arrow-down-gap + 30px; // checkbox-spacing = 30
      height: 100%;
      background-color: @picker-tree-bg;
      top: 0;
      margin-left: -58px; // 10px + 36px + 12px
    }

    &-active > .@{ctpns}-view-checknode-label,
    &:focus,
    &:hover {
      background-color: @picker-tree-node-hover-bg;
    }
  }

  &-disabled {
    color: @picker-menu-item-disabled-color;
    cursor: @cursor-disabled;

    > .@{ctpns}-view-checknode-label {
      cursor: @cursor-disabled;

      &,
      &:hover {
        background: transparent;
      }
    }
  }

  &:not(&-disabled):focus > .@{ctpns}-view-checknode-label {
    background-color: @picker-tree-node-hover-bg;
  }
}

// Checkbox
.@{ctpns}-view-input-wrapper {
  position: absolute;
  width: @checkbox-width-height;
  height: @checkbox-width-height;
  display: inline-block;
  left: @checkbox-sense-width+10px;
  top: @checkbox-sense-width;

  [type='checkbox'] {
    display: none;
  }

  &::before,
  &::after,
  .@{ctpns}-view-inner::before,
  .@{ctpns}-view-inner::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
  }

  &::before,
  .@{ctpns}-view-inner::before,
  .@{ctpns}-view-inner::after {
    width: @checkbox-width-height;
    height: @checkbox-width-height;
  }

  // Sensing field
  &::after {
    top: -@checkbox-sense-width;
    right: -@checkbox-sense-width;
    bottom: -@checkbox-sense-width;
    left: -@checkbox-sense-width;
  }

  // Frame
  .@{ctpns}-view-inner::before {
    .checkbox-inner-wrap(@checkbox-default-border-color);

    .@{ctpns}-view-node-indeterminate &,
    .@{ctpns}-view-node-checked & {
      border-color: @checkbox-checked-border-color;
      background-color: @checkbox-checked-border-color;
    }

    .@{ctpns}-view-node-disabled:not(.@{ns}picker-check-menu-item-active) & {
      border-color: @checkbox-disabled-color;
      background-color: @checkbox-disabled-color;
    }

    .@{ctpns}-view-node-disabled.@{ns}picker-check-menu-item-active & {
      opacity: 0.3;
    }
  }

  // check mark
  .@{ctpns}-view-inner::after {
    .opacity(0);

    transform: rotate(45deg) scale(0);

    .@{ctpns}-view-node-checked & {
      border: solid @B000;
      width: 6px;
      height: 9px;
      margin-top: 2px;
      margin-left: 5px;
      opacity: 1;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) scale(1);
    }

    .@{ctpns}-view-node-indeterminate & {
      .opacity(1);

      border: solid @B000;
      height: 9px;
      border-width: 0 0 2px;
      transform: rotate(0deg) scale(1);
      width: 10px;
      margin-top: 0;
      margin-left: 3px;
    }
  }

  // :Focus
  &:focus {
    outline: none;

    .@{ctpns}-view-inner::before {
      .checkbox-inner-active-shadow(@checkbox-default-border-color);

      @hover: ~':hover';

      @{hover}& {
        .checkbox-inner-active-shadow(@checkbox-checked-border-color);
      }

      .@{ns}checkbox-checked & {
        .checkbox-inner-active-shadow(@checkbox-checked-border-color);
      }
    }
  }
}

// expand icon
.@{ctpns}-view-node-expand-icon-wrapper {
  display: inline-block;
  position: absolute;
  cursor: pointer;
  z-index: 1;

  > .@{ctpns}-view-node-expand-icon {
    display: inline-block;
    padding: @picker-tree-node-padding-vertical 0;
    padding-right: @picker-tree-arrow-down-gap;
    height: 36px;
    font-size: @picker-tree-node-font-size;
    line-height: @picker-tree-node-line-height;
    transform: rotate(-90deg);
    transform-origin: 3.5px 16px;
    margin-left: -2px;
    margin-top: 2px;
    font-family: @font-family-icon;
    font-style: normal;

    &::before {
      content: @picker-tree-arrow-down;
    }

    // expand children tree
    .@{ctpns}-view-open > .@{ctpns}-view-node & {
      transform: rotate(0deg);
      margin-left: 0;
      margin-top: 0;
    }
  }

  > .@{ctpns}-view-custom-icon {
    .node-item-prepend-icon;
  }
}

// node children
.@{ctpns}-view-node-children {
  > .@{ctpns}-view-children {
    display: none;

    .@{ctpns}-view-open& {
      display: block;
    }
  }
}

.@{ns}picker-menu {
  &.@{ctpns}-menu {
    padding-top: @picker-menu-padding;

    .@{ns}picker-search-bar {
      padding-top: 0;
    }
  }

  .@{ctpns}-view {
    padding: 0 @picker-menu-padding @picker-menu-padding 0;

    &-node > .@{ctpns}-view-checknode-label {
      .ellipsis;

      display: block;
    }
  }
}
