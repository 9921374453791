//
// Utility classes
// --------------------------------------------------

// Floats
// -------------------------

.clearfix {
  .clearfix();
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

// Toggling content
// -------------------------

// Note: Deprecated .hide in favor of .hidden or .sr-only (as appropriate) in v3.0.1
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate

.hidden {
  display: none !important;
}

// For Affix plugin
// -------------------------

.affix {
  position: fixed;
}

// Animations
// -------------------------
.fade {
  opacity: 0;
  transition: opacity 0.15s linear;

  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.in {
    display: block;
  }

  tr&.in {
    display: table-row;
  }

  tbody&.in {
    display: table-row-group;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease, visibility 0.35s ease;
}
